import { ReactNode } from "react";
import { Base } from "./styles";
interface LayoutLoginProps {
  children: ReactNode;
}
const LayoutLogin = ({
  children
}: LayoutLoginProps) => {
  return <Base data-sentry-element="Base" data-sentry-component="LayoutLogin" data-sentry-source-file="index.tsx">{children}</Base>;
};
export default LayoutLogin;