import { alpha } from "@mui/material";
import type { CSSProperties, MouseEvent, ReactNode } from "react";
import { forwardRef } from "react";
import { Base } from "./styles";
import { color } from "@mui/system";
interface ButtonProps {
  id?: string;
  type?: "button" | "submit";
  variant?: "primary" | "secondary" | "text";
  children: ReactNode;
  fullWidth?: boolean;
  zeroMinWidth?: boolean;
  minWidth?: string | number;
  disabled?: boolean;
  component?: "span" | "button" | "anchor";
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  autofocus?: boolean;
  mb?: string | number;
  mt?: string | number;
  color?: string;
  style?: CSSProperties;
}
const toStyle = (variant: ButtonProps["variant"], color?: string): any => {
  switch (variant) {
    case "primary":
      return {
        variant: "contained",
        color: color ?? "secondary",
        disableElevation: true
      };
    case "secondary":
      return {
        variant: "outlined",
        color: color ?? "secondary"
      };
    case "text":
      return {
        variant: "text",
        color: color ?? "secondary"
      };
  }
};
const toDisabledState = (variant: ButtonProps["variant"]): any => {
  switch (variant) {
    case "primary":
      return {
        "&:disabled": {
          backgroundColor: alpha("#F4D7C6", 0.7)
        }
      };
    case "secondary":
      return {
        "&:disabled": {
          backgroundColor: "transparent"
        }
      };
    case 'text':
      return {
        "&:disabled": {
          color: alpha("#F4D7C6", 0.5),
          backgroundColor: "transparent"
        }
      };
  }
};
const Button = forwardRef(({
  id,
  children,
  variant = "secondary",
  color = undefined,
  onClick,
  component = "button",
  type = "button",
  fullWidth = false,
  disabled = false,
  autofocus,
  zeroMinWidth = false,
  minWidth,
  mb,
  mt,
  style
}: ButtonProps, ref) => {
  return <Base id={id} type={type} ref={ref}
  // TODO - Investigate how to extend properties of a MUI component with Styled.
  // Using inline as a temp solution.
  sx={{
    maxWidth: fullWidth ? "none!important" : "inherit",
    display: fullWidth ? "block" : "inline-block",
    width: fullWidth ? "100%!important" : "auto",
    minWidth: minWidth ? minWidth : zeroMinWidth ? "0!important" : "16.5em",
    mb: mb ? mb : "",
    mt: mt ? mt : "",
    ...toDisabledState(variant)
  }} {...toStyle(variant, color)} onClick={onClick} disabled={disabled} component={component} autoFocus={autofocus} style={style}>
        {children}
      </Base>;
});
export default Button;