// Fix to MUI issue with <Autocomplete> not re-rendering when opening why? (Safari of course)
import { FocusEvent } from "react";

export const inputForceRedraw = (
  focusEvent: FocusEvent<HTMLDivElement | HTMLTextAreaElement, Element>
) => {
  const element = focusEvent.currentTarget;
  const inputContainer = element.closest(".MuiInputBase-root");
  if (!element || !inputContainer) return;
  requestAnimationFrame(() => {
    const fieldset = inputContainer.querySelector("fieldset");
    if (!fieldset) return;
    const display = element.style.display;
    fieldset.style.display = "none";
    void fieldset.offsetHeight;
    fieldset.style.display = display;
    console.log("<<< redrawn >>>");
  });
};

export const inputForceRedrawAutocomplete = (
  focusEvent: FocusEvent<HTMLDivElement | HTMLTextAreaElement, Element>
) => {
  const element = focusEvent.currentTarget;
  if (!element) return;
  requestAnimationFrame(() => {
    const fieldset = element.querySelector("fieldset");
    if (!fieldset) return;
    const display = element.style.display;
    fieldset.style.display = "none";
    void fieldset.offsetHeight;
    fieldset.style.display = display;
    console.log("<<< redrawn >>>");
  });
};
