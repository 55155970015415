import { useTranslation } from "react-i18next";
import type { ReactNode } from "react";
import { Typography, FormHelperText, Link, Stack } from "@mui/material";
import { Base, Brand, BaseHeader, BaseForm, BaseFooter, FormGroup, LinkGroup, VisuallyHidden, Divider, Heading } from "./styles";
import { useRouter } from "next/router";
import { COMMON, REGISTRATION } from "src/i18n/constants/namespaces";
import LanguagePicker from "src/components/LanguagePicker";
interface LoginPanelFormItem {
  children: ReactNode;
  lastItem?: boolean;
}
const FormItem = ({
  children,
  lastItem
}: LoginPanelFormItem) => <FormGroup lastItem={lastItem} data-sentry-element="FormGroup" data-sentry-component="FormItem" data-sentry-source-file="index.tsx">{children}</FormGroup>;
const FormLink = ({
  children
}: LoginPanelFormItem) => <LinkGroup align="right" data-sentry-element="LinkGroup" data-sentry-component="FormLink" data-sentry-source-file="index.tsx">{children}</LinkGroup>;
interface LoginPanelProps {
  helperText?: string;
  children: ReactNode;
}
const LoginPanel = ({
  children,
  helperText = ""
}: LoginPanelProps) => {
  const {
    t: getTranslation
  } = useTranslation([COMMON, REGISTRATION]);
  const router = useRouter();
  return <Base data-sentry-element="Base" data-sentry-component="LoginPanel" data-sentry-source-file="index.tsx">
      <BaseHeader data-sentry-element="BaseHeader" data-sentry-source-file="index.tsx">
        <Stack direction="row" justifyContent="space-between" alignItems="baseline" spacing={2} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
          <Brand onClick={() => router.push("https://www.altaicasting.com")} data-sentry-element="Brand" data-sentry-source-file="index.tsx">
            <VisuallyHidden data-sentry-element="VisuallyHidden" data-sentry-source-file="index.tsx">
              {getTranslation("companyName.short", {
              ns: COMMON
            })}
            </VisuallyHidden>
          </Brand>
          <LanguagePicker data-sentry-element="LanguagePicker" data-sentry-source-file="index.tsx" />
        </Stack>
        <Divider data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
        <Heading variant="h3" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
          {getTranslation("login.pageTitle", {
          ns: REGISTRATION
        })}
        </Heading>
      </BaseHeader>
      {children}
      {helperText!.length > 0 && <FormHelperText error>{helperText}</FormHelperText>}
      <Divider data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
      <BaseFooter data-sentry-element="BaseFooter" data-sentry-source-file="index.tsx">
        <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {getTranslation("login.notYetRegistered", {
          ns: REGISTRATION
        })}{" "}
          <Link underline="none" href="https://www.altaicasting.com" target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
            <strong>
              {getTranslation("login.createAnAccount", {
              ns: REGISTRATION
            })}
            </strong>
          </Link>
        </Typography>
      </BaseFooter>
    </Base>;
};
LoginPanel.Form = BaseForm;
LoginPanel.FormItem = FormItem;
LoginPanel.FormLink = FormLink;
export default LoginPanel;