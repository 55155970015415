import type { ReactNode } from "react";
import { Base, BaseGroupPrimary, BaseGroupSecondary, LoginBackgroundImage } from "./styles";
interface LoginProps {
  children: ReactNode;
}
const Login = ({
  children
}: LoginProps) => <BaseGroupPrimary data-sentry-element="BaseGroupPrimary" data-sentry-component="Login" data-sentry-source-file="index.tsx">{children}</BaseGroupPrimary>;
interface BackgroundProps {}
const Background = ({}: BackgroundProps) => <BaseGroupSecondary data-sentry-element="BaseGroupSecondary" data-sentry-component="Background" data-sentry-source-file="index.tsx">
    <LoginBackgroundImage src="/background/altai-login-background.jpg" unoptimized priority objectFit="cover" layout="fill" alt="" data-sentry-element="LoginBackgroundImage" data-sentry-source-file="index.tsx" />
  </BaseGroupSecondary>;
interface ViewLoginProps {
  children: ReactNode;
}
const ViewLogin = ({
  children
}: ViewLoginProps) => <Base data-sentry-element="Base" data-sentry-component="ViewLogin" data-sentry-source-file="index.tsx">{children}</Base>;
ViewLogin.Login = Login;
ViewLogin.Background = Background;
export default ViewLogin;