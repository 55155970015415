import { Backdrop, CircularProgress } from "@mui/material";
import { Base } from "./styles";
interface FullScreenLoaderProps {
  loading?: boolean;
}
const FullScreenLoader = ({
  loading = false
}: FullScreenLoaderProps) => <Base open={loading!} data-sentry-element="Base" data-sentry-component="FullScreenLoader" data-sentry-source-file="index.tsx">
    <CircularProgress data-sentry-element="CircularProgress" data-sentry-source-file="index.tsx" />
  </Base>;
export default FullScreenLoader;