import { Controller } from "react-hook-form";
import { TextField as MUITextField } from "@mui/material";
import isDefined from "src/utils/isDefined";
import { VALIDATIONS } from "src/i18n/constants/namespaces";
import i18next from "i18next";
import { inputForceRedraw } from "src/features/common/utils/inputForceRedraw";
interface TextFieldProps {
  id: string;
  name: string;
  // TODO - Investigate control type definition
  control: any;
  type?: "text" | "password" | "email" | "number" | "tel" | "search";
  required?: boolean;
  pattern?: RegExp;
  multiline?: boolean;
  disabled?: boolean;
  label: string;
  autoComplete?: string;
}
const TextField = ({
  control,
  name,
  label,
  required,
  pattern,
  multiline,
  disabled,
  type = "text",
  autoComplete
}: TextFieldProps) => {
  return <Controller control={control} name={name} rules={{
    required,
    pattern: pattern ? {
      value: pattern,
      message: ""
    } : undefined
  }} render={({
    field,
    fieldState
  }) => <MUITextField {...field} autoComplete={autoComplete} fullWidth type={type} required={required} multiline={multiline} disabled={disabled} onFocus={inputForceRedraw} onBlur={inputForceRedraw}
  // Enforces consistent row height
  rows={multiline ? 5 : 0} label={label} error={isDefined(fieldState.error)} helperText={fieldState?.error?.message ? i18next.t(fieldState.error.message, {
    ns: VALIDATIONS,
    defaultValue: fieldState.error.message
  }) : ""} />} data-sentry-element="Controller" data-sentry-component="TextField" data-sentry-source-file="index.tsx" />;
};
export default TextField;