import i18next from "i18next";
import { REGISTRATION } from "src/i18n/constants/namespaces";
import { AuthContext } from "src/machines/appMachine";

const toInvalidLoginMessage = (errorCode: AuthContext["errorCode"]): string => {
  switch (errorCode) {
    // Generalise message, not ideal advising user on specific detail
    case "auth/user-not-found":
    case "auth/wrong-password":
      return i18next.t("login.loginError", {
        context: "invalidCredentials",
        ns: REGISTRATION,
      });
    case "auth/too-many-requests":
      return i18next.t("login.loginError", {
        context: "tooManyRequests",
        ns: REGISTRATION,
      });
    default:
      // Return empty string if no error
      return "";
  }
};

export default toInvalidLoginMessage;
