import styled from "@emotion/styled";
import { Button as MUIButton } from "@mui/material";
import { minWidth } from "@mui/system";
import em from "src/utils/em";
import mq from "src/utils/mq";
import space from "src/utils/space";

//min-width: ${em(256)};
export const Base = styled(MUIButton)`
  max-width: none;
  width: 100%;
  padding-left: ${space(4)};
  padding-right: ${space(4)};
  text-align: center;
  display: flex;

  ${mq("sm")} {
    white-space: nowrap;
    padding-left: ${space(4)};
    padding-right: ${space(4)};
    min-width: ${(x) => {
      const minWidth = (x?.sx as any)?.['minWidth']
      return minWidth;
    }};
    text-align: center;
    width: auto;
  }

  &:disabled: {
    pointer-events: none;
  }
`;
